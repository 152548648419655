define('custard-pie/schedule/model', ['exports', 'ember-data'], function (exports, _emberData) {

  var Schedule = _emberData['default'].Model.extend({
    date: _emberData['default'].attr('date'),
    time: _emberData['default'].attr('string'),
    venue: _emberData['default'].attr('string'),
    location: _emberData['default'].attr('string')
  });

  exports['default'] = Schedule;
});