define('custard-pie/router', ['exports', 'ember', 'custard-pie/config/environment'], function (exports, _ember, _custardPieConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _custardPieConfigEnvironment['default'].locationType,
    rootURL: _custardPieConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('schedule');
    this.route('playlist');
    this.route('photos', function () {
      this.route('selectedPhoto', { path: '/:photo_id' });
    });
    this.route('members', function () {
      this.route('member', { path: '/:member_name' });
    });
  });

  exports['default'] = Router;
});