define('custard-pie/application/adapter', ['exports', 'ember-data-fixture-adapter'], function (exports, _emberDataFixtureAdapter) {
  // import DS from 'ember-data';
  //
  // export default DS.JSONAPIAdapter.extend({
  // });

  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDataFixtureAdapter['default'];
    }
  });
});