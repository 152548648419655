define('custard-pie/index/route', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].$.getJSON('https://71kvsggn.api.sanity.io/v1/data/query/schedule?query=*[]|order(date)').then(function (data) {
        return data.result;
      }).then(function (events) {
        return events.filter(function (event) {
          var date = (0, _moment['default'])(event.date);

          if (date.diff((0, _moment['default'])(), 'days') >= 0) {
            return event;
          }
        }).slice(0, 3);
      });
    }
  });
});