define('custard-pie/members/member/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.get('store').findAll('member').then(function (members) {
        return members.find(function (m) {
          return m.get('name').toLowerCase() === params.member_name.replace('_', ' ');
        });
      });
    }
  });
});