define('custard-pie/playlist/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    groupedByBandName: _ember['default'].computed('model.bands.@each.bandName', function () {
      var result = [];
      this.get('model').forEach(function (band) {
        var group = _ember['default'].get(band, 'bandName')[0];
        var hasGroup = !!result.findBy('group', _ember['default'].get(band, 'bandName')[0]);

        if (!hasGroup) {
          result.pushObject(_ember['default'].Object.create({
            group: group,
            content: []
          }));
        }
        result.findBy('group', group).get('content').pushObject(band);
      });
      return result;
    })

  });
});